/* eslint jsx-a11y/heading-has-content: 0 */

import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import tw, { styled } from 'twin.macro';
import Layout from './Layout';

const Container = styled.div`
  h1 {
    ${tw` text-primary md:leading-tight my-3  text-4xl font-bold`};
  }
  h3,
  h4 {
    ${tw` text-primary md:leading-tight my-3 text-3xl font-bold`};
  }
  p {
    ${tw`mb-3 text-gray-400 text-base`};
  }
  .mdx-content {
    margin: 4rem 10rem;
  }
  br {
    ${tw`mb-4`};
  }
  a {
    ${tw`text-primary hover:underline`};
  }
  ul {
    ${tw`list-disc ml-4 text-gray-400`};
  }
  @media only screen and (max-width: 767px) {
    .mdx-content {
      margin: 2rem;
    }
  }
  .about-area {
    margin-top: 8rem;
  }
  .rn-finding-us-area {
    margin-bottom: 8rem;
  }
  .page-content {
    img {
      display: flex;
      margin: 3rem auto 4rem auto;
      box-shadow: ${(props) => props.theme.boxShadow};
    }
  }
`;

export default function PageTemplate({ children }) {
  return (
    <Layout>
      <section tw="text-gray-600 ">
        <div tw="container px-5 md:py-24 py-10 mx-auto">
          <Container tw="container">
            <MDXProvider
            // components={{
            //   h1: (props) => <h1 className="title is-1" {...props} />,
            //   h2: (props) => <h2 className="title is-2" {...props} />,
            //   h3: (props) => <h3 className="title is-3" {...props} />,
            //   p: (props) => <p className="" {...props} />,
            // }}
            >
              {children}
            </MDXProvider>
          </Container>
        </div>
      </section>
    </Layout>
  );
}
